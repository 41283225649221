import React, { useState, useEffect, useCallback, useContext } from 'react';
import AppointmentCalendar from './AppointmentCalendar';
import AppointmentTimer from './AppointmentTimer';
import TicketDetailCard, { ClusterLead, FEDetails } from './TicketDetailCard';
import { useNavigate } from 'react-router-dom';
import { GetQueryParam } from './utils/common';
import TextModal from './TextModal/TextModal';
import { AppointmentContext } from './App';
import config from './config';
import moment from 'moment';

const AppointmentMainContainer = () => {
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [ticketAndFeDetail, seTicketAndFeDetail] = useState({});

    const token = GetQueryParam('data')
    const [isModalOpen, setModalOpen] = useState(false);
    const [openSuccessTextModal,setOpenSuccessTextModal]=useState(false)
    const {setTicketAndFeDetailData, selectedDateAndTime, setSelectedDateAndTime, setIsFormButtonLoading,setSuccess,success } = useContext(AppointmentContext);
   const [submitResponseData,setSubmitResponseData]=useState({})
    let baseUrl = `https://auhyfy2waa.execute-api.ap-south-1.amazonaws.com/dev/api/`;
    if (config.name === 'prod') baseUrl = `https://ydzkg5tj55.execute-api.ap-south-1.amazonaws.com/prod/api/`;

    const fetchData = useCallback(async () => {
        try {
            const queryParams = {
                data: token,
            };

            const queryString = new URLSearchParams(queryParams).toString();
         
            setLoader(true);
            let baseUrl = `https://auhyfy2waa.execute-api.ap-south-1.amazonaws.com/dev/api/`;
            if (config.name === 'prod') baseUrl = `https://ydzkg5tj55.execute-api.ap-south-1.amazonaws.com/prod/api/`;
            const apiUrl = `${baseUrl}appointment/getTicketDetails?${queryString}`;

            const response = await fetch(apiUrl);
            setLoader(false);

            var jsonData = await response.json();
            console.log('jsonData:', jsonData)
    
            if(jsonData.code===400){
                navigate('/linkExpired')
            } else if(jsonData.code===500) {
                navigate('/failure');
            }
           
            seTicketAndFeDetail(jsonData.data);
            setTicketAndFeDetailData(jsonData.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [navigate, token]);

   
    useEffect(() => {
        if(!success){
            fetchData();
        }
    
        console.log('ticketAndFeDetail:', ticketAndFeDetail)
    }, [fetchData,success]);
  
    const submitData = async ({ date, time }) => {
        setIsFormButtonLoading(true);
        const fullDate = date.split('/');
        const dateTimeString = `${fullDate[2]}-${fullDate[1]}-${fullDate[0]} ${time.hr}:${time.min} ${time.time}`;
        const utcDate = moment(dateTimeString, 'YYYY-MM-DD h:mm A').utc();
        const data = {
            ticketId: ticketAndFeDetail.ticket.id,
            token: ticketAndFeDetail.token,
            selectedDate: utcDate,
            recipient: ticketAndFeDetail.recipient,
        };

        const apiUrl = `${baseUrl}appointment/setExecutionDateForTicket`;

        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        var jsonData = await response.json();
        setSubmitResponseData(jsonData)
        setModalOpen(false);

        setIsFormButtonLoading(false);

        if (jsonData.code === 200 ) {
         
            if( jsonData.data.pageStatus==="Disable"){
                setOpenSuccessTextModal(true)
            }else{
                setSuccess(true)
                navigate('/success');
            }

        }
         else if(jsonData.code===500) {
            navigate('/failure');
        }
       else if(jsonData.code===400){
            console.log('jsonData.code:', jsonData.code)
            navigate('/linkExpired')
        }
        else if(jsonData.code===404){
            console.log('jsonData.code:', jsonData.code)
            navigate('/linkExpired')
        }

  };

    const openConfirmationModal = ({ date, time }) => {
        if (time && date) {
            setSelectedDateAndTime({ date, time });
            setModalOpen(true);
        } else {
            console.log('select time and date both');
        }
    };

    const onCancelClick = () => {
        setSelectedDateAndTime(pre=>({...pre,time:''}));
        setModalOpen(false);
    };

    return (
        <>
            <div style={{ background: '#F8F8F8' }} className=' w-full mainContainer  pt-[6vh] pr-[1vh] overflow-hidden  '>
    
                <div className='heading text-scogoprimary font-bold '>
                  {
                   ticketAndFeDetail?.ticket && ticketAndFeDetail?.pageStatus==="Disable"?
                  <div className=' text-2xl text-scogoclosed pl-4 pt-6 h-24'> {ticketAndFeDetail?.message}</div> 
                  
                   :<>
                  <div className=' text-3xl pl-4 pt-6 '>Schedule A Field Engineer</div>
                    <div className='text-lg pt-2 pl-4'>Booking can be scheduled for up to 15 days.</div>
                  </>}
                    
                </div>
                <div className='ticketDetailCard overflow-hidden ' style={{ background: '#F8F8F8' }}>
                    <TicketDetailCard setLoader={setLoader} loader={loader} ticketAndFeDetail={ticketAndFeDetail} />
                </div>
                <div className='appointmentCalendar  pt-4'>
                    <AppointmentCalendar setLoader={setLoader} loader={loader} openConfirmationModal={openConfirmationModal} ticketAndFeDetail={ticketAndFeDetail} />
                </div>
                <div className='appointmentTimer pt-4'>
                    <AppointmentTimer setLoader={setLoader} loader={loader} openConfirmationModal={openConfirmationModal} ticketAndFeDetail={ticketAndFeDetail} />
                </div>
                <div className='feDetail flex items-end w-full pt-4'>
                    <FEDetails Lable={'Name'} ticketAndFeDetail={ticketAndFeDetail} setLoader={setLoader} loader={loader} />
                </div>
              {ticketAndFeDetail?.ticket?.cluster?.id ?  <div className='clusterLead flex items-end w-full pt-4'>
                    <ClusterLead Lable={'Name'} ticketAndFeDetail={ticketAndFeDetail} setLoader={setLoader} loader={loader} />
                </div>:<></>}   
            </div>
            {isModalOpen && (
                <TextModal
                    onCancelClick={onCancelClick}
                    heading={'Book Appointment'}
                    text={`Do you want to set Appointment Date at ${selectedDateAndTime.time.hr}:${selectedDateAndTime.time.min} ${selectedDateAndTime.time.time} on ${selectedDateAndTime.date}?`}
                    closeOnBackdropClick={false}
                    buttons={[
                        {
                            showLoading: true,
                            textOrComponent: 'YES',
                            type: 'blue',
                            buttonClass: 'hover:bg-scogoprimary',
                            onClick: () => {
                                submitData({ date: selectedDateAndTime.date, time: selectedDateAndTime.time });
                            },
                        },
                        {
                            textOrComponent: 'NO',
                            type: 'red',
                            onClick: onCancelClick,
                            buttonClass: 'hover:bg-scogobgsky w-15',
                        },
                    ]}
                />
            )}
            {(openSuccessTextModal && !isModalOpen)&&
            <TextModal
               heading={'Set Appointment Failed'}
               text={submitResponseData.data.message}
               closeOnBackdropClick={false}
               headerClass={'text-font24 text-scogo30 mb-2 text-scogoclosed font-bold'}
            />}
        </>
    );
};
export default AppointmentMainContainer;


import './App.css';
import React, { createContext, useContext, useState } from 'react';
import ErrorPage from './ErrorPage/ErrorPage';
import Header from './Header/Header';
import ThankYou from './ThankYou/ThankYou';
import { Route, Routes } from 'react-router-dom';
import AppointmentMainContainer from './AppointmentMainContainer';
import NoResultFound from './NoResulFound';

export const AppointmentContext = createContext(null);

function App() {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [selectedDateAndTime, setSelectedDateAndTime] = useState({ date: '', time: '' });
    const [isFormButtonLoading, setIsFormButtonLoading] = useState(false);
    const[ticketAndFeDetailData,setTicketAndFeDetailData]=useState()
    const[isDateSelected,setIsDateSelected]=useState(false)
    return (
        <AppointmentContext.Provider value={{ error,
         success, setError, setSuccess, selectedDateAndTime, setSelectedDateAndTime, isFormButtonLoading, 
         setIsFormButtonLoading,ticketAndFeDetailData,setTicketAndFeDetailData,isDateSelected,setIsDateSelected }}>
            <div style={{ background: '#F8F8F8' }}>
                <Header />
                <Routes>
                    <Route path='/' element={success?<ThankYou />:<AppointmentMainContainer />} />
                    <Route path='/success' element={<ThankYou />} />
                    <Route path='/failure' element={<ErrorPage />} />
                    <Route path='/linkExpired' element={ <NoResultFound/>} />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            
            </div>
        </AppointmentContext.Provider>
    );
}

export default App;

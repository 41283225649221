const Header = () => {
    return <div as='div' className='header fixed  w-full z-50 flex items-center justify-between  '>

        <div className={`h-full w-full flex items-center justify-between `}>
            <div
                className='flex items-center cursor-pointer'
            >
                <div className='shrink-0'>
                    <img className={`headerLogo`} src={process.env.PUBLIC_URL + '/img/scogo-logo-black-1.png'} alt='Workflow' />
                </div>
            </div>

        </div>


    </div>
}
export default Header
import React from 'react';
import { SkeltonCardOne } from './SkeletonLoader';
import moment from 'moment'
import IconToolTip from './IconToolTip';
const TicketDetailCard = ({loader, ticketAndFeDetail, error}) => {
    let branchname=ticketAndFeDetail?.ticket?.site?.site_branch_name||''
       let pincode=ticketAndFeDetail?.ticket?.job_fk_pincode_id||''
       let city=ticketAndFeDetail?.ticket?.site?.city?.city_name||''
       let state=ticketAndFeDetail?.ticket?.site?.state?.state_name||''
       let siteAddress=ticketAndFeDetail?.ticket?.job_site_address_1
       let adderess= siteAddress+' '+branchname +' ' +pincode+' '+ city+' '+state
let exDate= 'Yet to be assign'
if(ticketAndFeDetail?.ticket?.execution_date && moment(ticketAndFeDetail?.ticket?.execution_date).isValid()){
    exDate=moment(ticketAndFeDetail?.ticket?.execution_date).format('DD-MM-YYYY HH:mm')
}

    return (
        <>
            <div style={{ background: '#F8F8F8' }} className='flex  flex-col overflow-hidden h-full'>
                <div className='  pl-4 items-center h-full'>
                    {loader?<SkeltonCardOne/>: <div className=' py-4 h-full'>
                        <CardHeader header={'Ticket Details'} />
                        <div className=' flex flex-col bg-white rounded-md h-full ticketCardDiv pb-3'>
                            <CardDetails Lable={'Ticket:'} value={ticketAndFeDetail?.ticket?.job_ticket_number} />
                            <CardDetails Lable={'Project:'} value={ticketAndFeDetail?.ticket?.project?.project_name} />
                            <CardDetails Lable={'Site Id:'} value={ticketAndFeDetail?.ticket?.site?.external_site_id} />
                            <CardDetails Lable={'Name:'} value={ticketAndFeDetail?.ticket?.job_site_contact_name} />
                         { ticketAndFeDetail?.ticket?.job_site_contact_mobile&&
                           <CardDetails Lable={'Mobile:'} value={ticketAndFeDetail?.ticket?.job_site_contact_mobile} />}
                           {ticketAndFeDetail?.ticket?.description&&
                            <CardDetails Lable={'Discription:'} value={ticketAndFeDetail?.ticket?.description} />}
                            <CardDetails Lable={'Execution Date:'} value={exDate} />
                           {adderess&& <CardDetails Lable={'Site Address:'} value={adderess} lableClass='w-[11rem]'
                           valueClass='pl-[0%]' />}
                       </div>
                    </div>}
      
                </div>
            </div>
        </>
    );
};
export default TicketDetailCard;

export const CardDetails = ({ Lable, value }) => {
    return (
        <>
            <div className='inline-flex items-baseline px-2'>
                <div
                    className='font-medium pt-4 min-w-max'
                    style={{
                        fontSize: '1.2rem',
                    }}
                >
                    {Lable}
                </div>
                <div className='pl-2'>
                    <IconToolTip title={`${value}`}>
                        <div
                            style={{
                                fontSize: '1.2rem',
                            }}
                            className={'text-grayText  text-center font-medium pt-4'}
                        >
                            {value}
                        </div>
                    </IconToolTip>
                </div>
            </div>
        </>
    );
};
export const CardHeader = ({ header }) => {
    return (
        <>
            <div style={{ fontSize: '1.2rem' }} className='border-1 border-scogoprimary  bg-scogoprimary flex px-[1vw]  items-center text-white h-[5vh] rounded-t-6px'>
                {header}
            </div>
        </>
    );
};

export const ClusterLead = ({ ticketAndFeDetail,loader}) => {
    const name=ticketAndFeDetail?.ticket?.cluster?.first_name +' '+ticketAndFeDetail?.ticket?.cluster?.last_name||" "
   
    return (
        <>
        {loader?<SkeltonCardOne/>:   <div style={{ background: '#F8F8F8' }} className='pl-4  w-full  '>
                <div className=''>
                    <CardHeader header={'Cluster Details'} />
                    <div className='  bg-white  gap-2 pb-4 rounded-b-md'>
                        <CardDetails Lable={'Cluster:'} value={name||" "} />
                        <CardDetails Lable={'Mobile:'} value={ticketAndFeDetail?.ticket?.cluster?.mobile} />
                    </div>
                </div>
            </div>}
         
        </>
    );
};
export const FEDetails = ({ticketAndFeDetail,loader }) => {

    const name=ticketAndFeDetail?.ticket?.accepted_fe?.first_name +' '+ticketAndFeDetail?.ticket?.accepted_fe?.last_name||" "
    return (
        <>{loader?<SkeltonCardOne/>:
        <div style={{ background: '#F8F8F8' }} className=' pl-4   w-full'>
        <div className=''>
            <CardHeader header={'FE Details'} />
         { ticketAndFeDetail?.ticket?.accepted_fe  ?<div className='   bg-white pb-4 rounded-b-md'>
    
         <CardDetails Lable={'FE:'} value={name} />
                <CardDetails Lable={'Mobile:'} value={ticketAndFeDetail?.ticket?.accepted_fe?.mobile ||' '} />
            </div>:
            <div className=' bg-white pb-4 rounded-b-md'>
                   <CardDetails Lable={'FE:'} value={' Not assign'} />  
            </div>
            }  
        </div>
    </div>
        }
         
        </>
    );
};
